import React from 'react'

export default function Clock({ color }) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill={color || '#A8D0E6'}>
        <path d="M8.1,0.6c4.2,0,7.7,3.4,7.7,7.7s-3.4,7.6-7.7,7.6s-7.6-3.4-7.6-7.6S3.9,0.6,8.1,0.6z
             M8.1,2.3c-3.3,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9s6-2.7,6-5.9S11.4,2.3,8.1,2.3z M8.1,3.3c0.4,0,0.8,0.3,0.8,0.8l0,0.1v3.6l2.3,1.1
            c0.4,0.2,0.6,0.7,0.4,1.1l0,0.1c-0.2,0.4-0.7,0.6-1.1,0.4l-0.1,0L7.7,9C7.5,8.8,7.3,8.6,7.3,8.3l0-0.1V4.1C7.3,3.7,7.6,3.3,8.1,3.3z" />
    </svg>
  )
}
