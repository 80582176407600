import React, {useState} from 'react'
import {Link} from 'gatsby'
import Heading from 'src/components/typography/Heading'
import {useTranslation} from 'react-i18next'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import {usePageContext} from 'src/context/PageContext'
import {DefaultAvatarLogo} from 'src/components/atoms/DefaultAvatar'

export default function BookATeacherBanner({teacher}) {
    const {t} = useTranslation();
    const {teacherProfile} = useLocalizedRoutes();
    const {lang} = usePageContext();
    const [imgError, setImgError] = useState(false);

    return (
        <div
            className="w-full rounded bg-dark-blue-600 p-6 md:p-10 text-white flex flex-col md:flex-row justify-between items-center">
            {teacher &&
            <>
                <div className="flex items-center">
                    <div className="h-16 w-16 md:h-20 md:w-20">
                        {imgError ?
                            <DefaultAvatarLogo/> :
                            <img
                                src={teacher.profile_picture_url}
                                onError={() => setImgError(true)}
                                alt={teacher.first_name}
                                className="h-16 w-16 md:h-20 md:w-20 rounded-full"/>
                        }
                    </div>
                    <div>
                        <Heading type="caption" className="pl-6 md:text-center">
                            {teacher.first_name} {teacher.last_name}
                        </Heading>
                        <Link
                            className="duration-300 underline pl-6 text-center md:hidden"
                            to={`${teacherProfile}/${teacher.id}`}>
                            {t('bookATeacher.viewProfile')}
                        </Link>
                    </div>
                    <div className="pl-3 hidden md:block">
                        {teacher.instruments.map((inst, i) => (
                            <span className="capitalize" key={i}>
                            {inst.translations[lang]}{teacher.instruments.length - 1 === i ? '' : ', '}
                        </span>
                        ))}
                        <span> {t('teacherProfile.lessons')}</span>
                    </div>
                </div>
                <Link
                    className="hidden md:block duration-300 underline pl-8 text-center"
                    to={`${teacherProfile}/${teacher.id}`}>
                    {t('bookATeacher.viewProfile')}
                </Link>
            </>
            }
        </div>
    )
}
