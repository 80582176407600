import React, { useEffect, useContext, Fragment } from 'react'
import {useTranslation} from 'react-i18next'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import {Context as BookingContext } from 'src/context/BookingContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import BookATeacherBanner from 'src/components/sections/booking/BookATeacherBanner'
import Check from 'src/components/icons/Check'
import Calendar from 'src/components/icons/Calendar'
import Clock from 'src/components/icons/Clock'
import {DateTime} from 'luxon'

export default function BookingCompleted(props) {
    const { t } = useTranslation();
    const { setTheme } = useContext(ThemeContext);
    const {state: {teacher, lessonsToSchedule }} = useContext(BookingContext);

    useEffect(() => setTheme('inverse'), []);

    return (
        <MarketingLayout {...props} title={t('bookingCompleted.title')}>
            <div className="container mx-auto px-4 lg:px-20 mb-32 pt-12">
                <h2 className="font-semibold text-4xl mb-6">{t('bookingCompleted.title')}</h2>
                <BookATeacherBanner teacher={teacher}/>
                <div className="py-4">
                    {lessonsToSchedule.map((lesson, index) => {
                        return (
                            <div key={index} className="my-6 flex items-center flex-wrap">
                                <div className="flex-grow flex items-center flex-wrap">
                                    <div className="w-full md:w-4/12 lg:w-3/12 xl:w-2/12 mb-2 md:mb-0">
                                        <p className="font-bold text-lg">{`Lesson ${index + 1}`}</p>
                                    </div>
                                    <div className="w-6/12 md:w-3/12 lg:w-3/12 xl:w-2/12 flex items-center">
                                        <Calendar
                                            color={'#363636'}
                                        />
                                        <p className="pl-3">{DateTime.fromISO(lesson.selectedDate, {zone: 'gmt'}).toFormat('LLL dd yyyy')}</p>
                                    </div>
                                    <div className="w-6/12 md:w-3/12 lg:w-3/12 xl:w-2/12 flex items-center">
                                        <Clock
                                            color={'#363636'}
                                        />
                                        <p className="pl-3">{DateTime.fromISO(lesson.selectedTime, {zone: 'gmt'}).toFormat('HH:mm')}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="p-6 px-12 bg-dark-blue-600 rounded flex items-center">
                    <Check width={23} />
                    <p className="text-white pl-4">{t('bookingCompleted.success')}</p>
                </div>
                {/*<div
                    onClick={() => alert('downloadeuuuh')}
                    className="w-full md:w-6/12 border border-grey-500 rounded p-8 md:p-12 mt-8 flex items-center cursor-pointer hover:border-dark-blue-600 transition-colors duration-150"
                >
                    <Calendar
                        width={24}
                        color={'rgba(36, 48, 94, 1)'}
                    />
                    <div className="pl-10">
                        <p className="text-lg font-bold">Add to calendar</p>
                        <p className="text-xs text-grey-800">Download .ics</p>
                    </div>
                </div>*/}
            </div>
        </MarketingLayout>
    )
}
