import React from 'react'

export default function Calendar(props) {
    return (
        <svg width={props.width || 14} height={props.width * 1.14 || 16} viewBox="0 0 14 16" fill={props.color || 'rgba(168, 208, 230, 1)'}>
            <title>{"Calendar"}</title>
            <path
                d="M9.933.8a.8.8 0 01.797.723l.003.077v.266h.843a2.09 2.09 0 012.085 1.942l.006.15v9.151a2.09 2.09 0 01-2.091 2.091H2.424a2.09 2.09 0 01-2.09-2.09V3.956a2.09 2.09 0 012.09-2.09l1.375-.001L3.8 1.6a.8.8 0 011.596-.077L5.4 1.6v.266h3.733V1.6a.8.8 0 01.8-.8zm2.133 6.666H1.932l.001 5.643c0 .241.174.442.403.483l.088.008h9.152a.49.49 0 00.49-.49V7.465zm-8.267-4H2.424a.49.49 0 00-.49.491l-.002 1.909h10.134V3.957a.49.49 0 00-.402-.482l-.088-.008-.843-.001v.267a.8.8 0 01-1.596.077l-.004-.077v-.267H5.4l.001.267a.8.8 0 01-1.596.077L3.8 3.733v-.267z"
                fillRule="nonzero"
            />
        </svg>
    )
}
